<template>
  <div class="row mt-4">
    <div class="col-6">
      <material-input
        id="start-date"
        v-model="startDate"
        is-required
        variant="static"
        label="Begint op"
        type="date"
      />
    </div>

    <div class="col-6">
      <material-input
        id="start-time"
        v-model="startTime"
        is-required
        variant="static"
        label="&nbsp;"
        type="time"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-6">
      <material-input
        id="end-date"
        v-model="endDate"
        is-required
        variant="static"
        label="Eindigt op"
        type="date"
      />
    </div>

    <div class="col-6">
      <material-input
        id="end-time"
        v-model="endTime"
        is-required
        variant="static"
        label="&nbsp;"
        type="time"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-6">
      <material-switch
        id="may-take-guest"
        v-model="mayTakeGuestHandler"
        name="may-take-guest"
      >
        Deelnemers mogen gasten meenemen
      </material-switch>
    </div>
    <div class="col-6">
      <base-select
        v-if="mayTakeGuest"
        v-model="guestCountHandler"
        label="Aantal gasten"
        :options="[
          { label: '1',         code:   1 },
          { label: '2',         code:   2 },
          { label: 'Onbeperkt', code: 999 }
        ]"
      />
    </div>

    <material-textarea
      v-if="mayTakeGuest"
      v-model="mayTakeGuestDescriptionHandler"
      placeholder="Beschrijving gasten"
      label="Beschrijving gasten"
      variant="static"
    />
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { watch } from 'vue';
import { createVModelHandlers } from '@/helpers/vue';
import BaseSelect from '@/components/Forms/BaseSelect.vue';

import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';

export default {
  name: 'CalendarInfo',

  components: {
    BaseSelect,
    MaterialButton,
    MaterialInput,
    MaterialSwitch,
    MaterialTextarea
  },

  props: {
    endDateTime: {
      type: String,
      required: true,
    },
    mayTakeGuest: {
      type: Boolean,
      required: true,
    },
    mayTakeGuestDescription: {
      type: String,
      required: true
    },
    guestCount: {
      type: Number,
      required: true
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    startDateTime: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:endDateTime',
    'update:mayTakeGuest',
    'update:startDateTime',
    'update:guestCount',
    'update:mayTakeGuestDescription'
  ],

  data: () => ({
    temp: {
      endDate: '',
      endTime: '',
      startDate: '',
      startTime: ''
    },
  }),

  computed: {
    ...createVModelHandlers(
      'mayTakeGuest',
      'mayTakeGuestDescription',
      'guestCount'
    ),

    endDate: {
      get() {
        return this.dateGetter('end');
      },
      set(value) {
        this.temp.endDate = value;
      },
    },

    endDateTimeObject() {
      return new Date(this.endDateTime);
    },

    endTime: {
      get() {
        return this.timeGetter('end');
      },
      set(value) {
        this.temp.endTime = value;
      },
    },

    maxStartDate() {
      return this.endDate || '';
    },

    minEndDate() {
      return this.startDate || this.TODAY;
    },

    startDate: {
      get() {
        return this.dateGetter('start');
      },
      set(value) {
        this.temp.startDate = value;
      },
    },

    startDateTimeObject() {
      return new Date(this.startDateTime);
    },

    startTime: {
      get() {
        return this.timeGetter('start');
      },
      set(value) {
        this.temp.startTime = value;
      },
    },
  },

  created() {
    this.TODAY = this.parseDateAsDateString(new Date());
  },

  mounted() {
    this.initWatchers();
  },

  methods: {
    dateGetter(type) {
      if (this.temp[`${type}Date`]) {
        return this.temp[`${type}Date`];
      }

      if (!this[`${type}DateTime`]) {
        return '';
      }

      return this.parseDateAsDateString(this[`${type}DateTimeObject`]);
    },

    dateOrTimeChanged(type) {
      if (
        !this[`${type}Date`]
        || !this[`${type}Time`]
      ) {
        return;
      }

      this.$emit(
        `update:${type}DateTime`,
        new Date(`${this[`${type}Date`]} ${this[`${type}Time`]}`).toISOString(),
      );
    },

    initWatchers() {
      watch(
        [
          () => this.endDate,
          () => this.endTime,
        ],
        () => this.dateOrTimeChanged('end'),
      );

      watch(
        [
          () => this.startDate,
          () => this.startTime,
        ],
        () => this.dateOrTimeChanged('start'),
      );
    },

    parseDateAsDateString(date) {
      const year = date.getFullYear(),
            month = this.parseIntAs2CharacterString(date.getMonth() + 1),
            day = this.parseIntAs2CharacterString(date.getDate());

      return `${year}-${month}-${day}`;
    },

    parseIntAs2CharacterString(number) {
      return number.toString().padStart(2, '0');
    },

    timeGetter(type) {
      if (this.temp[`${type}Time`]) {
        return this.temp[`${type}Time`];
      }

      if (!this[`${type}DateTime`]) {
        return '';
      }

      const dateTimeObject = this[`${type}DateTimeObject`],
        hours = this.parseIntAs2CharacterString(dateTimeObject.getHours()),
        minutes = this.parseIntAs2CharacterString(dateTimeObject.getMinutes());

      return `${hours}:${minutes}`;
    },
  },
};
</script>