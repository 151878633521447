<template>
  <div 
    class="col-lg-3"
  >
    <side-nav
      :items="navigationItems"
    >
      <user-attendance
        :event-id="$route.params?.id"
        :event-title="fields.title"
        :event-start-date="fields.startDate"
        :users="fields.eventUsers ?? []"
      />
    </side-nav>
  </div>

  <div
    class="col-lg-9 mt-lg-0 mt-4"
  >
    <event-summary
      v-model:visual-image="fields.visualImage"
      v-model:visual-image-data="fields.visualImageData"
      :end-date-time="fields.endDate"
      :start-date-time="fields.startDate"
      :title="fields.title"
      :venue-name="fields.venueName"
    />

    <form 
      @submit.prevent="submit"
    >
      <base-fieldset 
        id="event-active"
      >
        <div
          class="row mt-4"
        >
          <div 
            class="col-12"
          >
            <material-switch
              id="active"
              v-model="fields.active"
              name="active"
            >
              Actief
            </material-switch>
          </div>
        </div>
      </base-fieldset>

      <base-fieldset
        id="basic-info"
        title="Algemeen"
      >
        <basic-info
          v-model:description="fields.description"
          v-model:title="fields.title"
          v-model:url="fields.url"
          :show-update="!isNewEvent"
        />
      </base-fieldset>

      <base-fieldset
        id="calendar-info"
        title="Agenda"
      >
        <calendar-info
          v-model:end-date-time="fields.endDate"
          v-model:may-take-guest="fields.mayTakeGuest"
          v-model:start-date-time="fields.startDate"
          v-model:guest-count="fields.mayTakeGuestAmount"
          v-model:may-take-guest-description="fields.mayTakeGuestDescription"
          :show-update="!isNewEvent"
        />
      </base-fieldset>

      <base-fieldset
        id="location-info"
        title="Locatie"
      >
        <location-info
          v-model:address="fields.address"
          v-model:city="fields.city"
          v-model:location-id="fields.locationId"
          v-model:venue-name="fields.venueName"
          v-model:zip-code="fields.zipCode"
          :show-update="!isNewEvent"
        />
      </base-fieldset>

      <div
        v-if="attachmentsModuleIsActive"
        id="attachments"
        class="card mt-4"
      >
        <div 
          class="card-body"
        >
          <file-upload
            v-model:files="files"
            :show-update="!isNewEvent"
            @update:files="updateFiles"
          />
        </div>
      </div>

      <div
        v-if="photoGalleryModuleIsActive"
        id="photo-gallery"
        class="card mt-4"
      >
        <div
          class="card-body"
        >
          <image-upload
            v-model:images="images"
            :show-update="!isNewEvent"
            @update="updateImages"
          />
        </div>
      </div>

      <base-fieldset
        id="user-list"
        title="Besloten"
      >
        <material-switch
          v-model="fields.isRestricted"
        >
          Besloten evenement?
        </material-switch>

        <collapse-box 
          :is-open="fields.isRestricted"
        >
          <user-list
            ref="userListRef"
            :user-ids="fields.invitedUsers"
            :show-update="!isNewEvent"
          />

          <group-list
            ref="groupListRef"
            :group-ids="fields.invitedGroups"
          />
        </collapse-box>

        <material-button
          v-if="!isNewEvent"
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
        >
          Update gegevens
        </material-button>
      </base-fieldset>

      <div
        v-if="!isNewEvent"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder dit evenement</h5>
              <p class="text-sm mb-0">
                Wanneer een evenement verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete Evenement
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <material-button
        v-if="isNewEvent"
        color="primary"
        variant="gradient"
        class="float-end mt-4 mb-0 mx-4"
      >
        Opslaan
      </material-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { 
  add,
  edit,
  get,
  remove,
  addGroupsToRestrictedEvent, 
  removeGroupFromRestrictedEvent
} from '@/api/providers/events';

import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import BasicInfo from '@/components/Events/Edit/BasicInfo.vue';
import CalendarInfo from '@/components/Events/Edit/CalendarInfo.vue';
import EventSummary from '@/components/Events/Edit/EventSummary.vue';
import LocationInfo from '@/components/Events/Edit/LocationInfo.vue';
import UserList from '@/components/Events/Edit/UserList.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';
import SideNav from '@/components/UI/SideNav.vue';
import UserAttendance from '@/components/Events/Edit/UserAttendance.vue';
import CollapseBox from '@/components/UI/CollapseBox.vue';

import { createYesNoSweetAlert } from '../../helpers/sweetalert';

import { 
  add as addFile, 
  get as getFiles, 
  edit as editFile,
  remove as removeFile 
} from "@/api/providers/attachments/events";

import {
  get as getImages,
  add as addImages,
  remove as removeImage
} from '@/api/providers/photo-gallery/events';

import FileUpload from '@/components/UI/FileUpload.vue';
import ImageUpload from '@/components/UI/ImageUpload.vue';

import { 
  moduleIsActive,
  getModuleDisplayName
} from '@/helpers/module';
import setRouteNameForModule from '@/helpers/route-name';
import { CreateItemLabel, EditItemLabel } from '@/helpers/labels';

import GroupList from '@/components/Shared/GroupList/GroupList.vue';

export default {
  name: 'EventEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    CalendarInfo,
    EventSummary,
    GroupList,
    LocationInfo,
    UserList,
    MaterialButton,
    MaterialSwitch,
    FileUpload,
    ImageUpload,
    SideNav,
    UserAttendance,
    CollapseBox
  },

  data: () => ({
    fields: {
      active: false,
      address: '',
      city: '',
      description: '',
      endDate: '',
      eventUsers: [],
      locationId: '',
      mayTakeGuest: false,
      mayTakeGuestAmount: 0,
      mayTakeGuestDescription: '',
      publishDate: new Date().toISOString(),
      startDate: '',
      title: '',
      url: '',
      venueName: '',
      visualImage: '',
      visualImageData: '',
      zipCode: '',
      isRestricted: false,
      invitedUsers: [],
      invitedGroups: []
    },

    files: [],
    images: [],
    loading: false,

    photoGalleryModuleIsActive: false,
    attachmentsModuleIsActive: false
  }),

  computed: {
    ...mapGetters('account', [
      'organizationId',
    ]),

    isNewEvent() {
      return this.$route.params?.id === undefined;
    },

    navigationItems() {
      const defaultItems = [
        {
          href: '#summary',
          icon: 'image',
          name: 'Samenvatting',
        },
        {
          href: '#basic-info',
          icon: 'notes',
          name: 'Algemeen',
        },
        {
          href: '#calendar-info',
          icon: 'event',
          name: 'Agenda',
        },
        {
          href: '#location-info',
          icon: 'location_on',
          name: 'Locatie',
        },
      ];

      let optionalItems = [];

      if (this.attachmentsModuleIsActive) {
        optionalItems = optionalItems.concat({
          href: '#attachments',
          icon: 'folder',
          name: getModuleDisplayName('Attachments')
        });
      }
      if (this.photoGalleryModuleIsActive) {
        optionalItems = optionalItems.concat({
          href: '#photo-gallery',
          icon: 'image',
          name: 'Media'
        });
      }

      return defaultItems
        .concat(optionalItems)
        .concat({
          href: '#user-list',
          icon: 'lock',
          name: 'Besloten'
        });
    },

    submitData() {
      return {
        ...this.fields,
        eventType: this.fields.isRestricted,
        organizationId: this.organizationId,
      };
    }
  },

  async beforeMount() { 
    setRouteNameForModule(
      'Events',
      `${ this.isNewItem  ? CreateItemLabel : EditItemLabel } `
    );
  },

  async mounted() {
    this.loadEventData();

    this.photoGalleryModuleIsActive = await moduleIsActive("PhotoGallery");
    this.attachmentsModuleIsActive = await moduleIsActive("Attachments");

    if (this.photoGalleryModuleIsActive) this.loadEventImages();
    if (this.attachmentsModuleIsActive)  this.loadEventAttachments();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
      addError: 'addError'
    }),

    async addEvent() {
      try {
        const createdEntity = await add(this.submitData);
        await this.updateAttachmentsAndImages(createdEntity.id);
        await this.$refs.userListRef.submit(createdEntity.id);

        await addGroupsToRestrictedEvent(
          this.$refs.groupListRef.addedGroupIds,
          createdEntity.id
        );

        this.handleSaveSuccess();
      }
      catch {
        this.handleSaveError();
      }
      this.loading = false;
    },

    async editEvent() {
      try {
        const updatedEntity = await edit(this.submitData);
        await this.updateAttachmentsAndImages(updatedEntity.id);
        await this.$refs.userListRef.submit(updatedEntity.id);

        await addGroupsToRestrictedEvent(
          this.$refs.groupListRef.addedGroupIds,
          updatedEntity.id
        );

        for (const groupId of this.$refs.groupListRef.removedGroupIds) {
          await removeGroupFromRestrictedEvent(
            groupId,
            updatedEntity.id
          );
        }

        this.handleSaveSuccess();
      }
      catch (error) {
        this.handleSaveError();
      }
      this.loading = false;
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is verwijderd.`,
        icon: {
          component: 'event',
        },
      });

      this.navigateToEventsOverview();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is opgeslagen.`,
        icon: {
          component: 'event',
        },
      });

      this.navigateToEventsOverview();
    },

    handleSaveError() {
      this.addNotification({
        description: `Er is een fout opgetreden tijdens het opslaan van ${this.fields.title}.`,
        icon: {
          component: 'event',
        },
      });
    },

    loadEventData() {
      if (this.isNewEvent) {
        return;
      }

      get(this.$route.params.id)
        .then(this.handleGetSuccess)
        .catch((error) => error.default());
    },

    navigateToEventsOverview() {
      this.$router.push({
        name: 'events',
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Evenement ${this.fields.title} verwijderen?`,
        () => { 
          remove(this.$route.params.id)
            .then(this.handleRemoveSuccess)
            .catch((error) => error.default());
        }
      )
    },

    async editFiles() {
      const files = this.files ?? [];

      files.filter(f => f.isUpdated && !f.isNew).forEach(async file => {
        try {
          await editFile(file.id, file.title);
        }
        catch (error) {
          this.addError(error);
        }
      });
    },

    async uploadNewFiles(eventId) {
      const files = this.files ?? [];

      files.filter(f => f.isNew && !f.isDeleted).forEach(async file => {
        try {
          await addFile(eventId, file.title, file.data);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async deleteFiles() {
      const files = this.files ?? [];

      files.filter(f => f.isDeleted && !f.isNew).forEach(async file => {
        try {
          await removeFile(file.id);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async uploadImages(eventId) {
      const images = (this.images ?? []).filter(i => i.isNew && !i.isDeleted);
        
      try {
        await addImages(eventId, images.map(i => i.data));
      }
      catch (error) {
        throw new Error(error);
      }
    },

    async deleteImages() {
      const images = (this.images ?? []).filter(i => i.isDeleted && !i.isNew);

      images.forEach(async image => {
        try {
          await removeImage(image.id);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async updateAttachmentsAndImages(eventId) {
      try {
        if (this.attachmentsModuleIsActive) {
          await this.uploadNewFiles(eventId);
          await this.editFiles(eventId);
          await this.deleteFiles();
        }

        if (this.photoGalleryModuleIsActive) {
          await this.uploadImages(eventId);
          await this.deleteImages(eventId);
        }
     }
      catch (error) {
        this.addError(error);
      }
    },

    loadEventAttachments() {
      if (this.isNewEvent) return;

      getFiles(this.$route.params?.id)
        .then(this.handleGetFilesSuccess)
          .catch(() => {});
    },

    loadEventImages() {
      if (this.isNewEvent) return;

      getImages(this.$route.params?.id)
        .then(response => { this.handleGetImagesSuccess(response) })
        .catch(() => {});
    },

    handleGetFilesSuccess(data) {
      this.files = data;
    },

    handleGetImagesSuccess(data) {
      this.images = data?.photoGalleryImages.map(i => { 
        return {
          src: i.visualImage,
          id: i.id
        };
      });
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewEvent) {
        this.addEvent();
      } else {
        this.editEvent();
      }
    },
  },
};
</script>
